<template>
  <CContainer class="d-flex content-center min-vh-100">
    <CRow class="col-12 d-flex justify-content-center">
      <CCol lg="6" sm="12">
        <CCard>
          <CCardBody>
            <CForm @submit.prevent="handleSubmit">
                <h1>{{ ('Recover Password') }}</h1>
                <p v-if="isLinkValid" class="text-muted">{{ ('Insert new password') }}</p>
                <p v-else class="is-invalid">{{("Invalid Link")}}</p>
                <!-- Password -->
                <CInput :placeholder="('New password')" type="password" autocomplete="curent-password" v-model="password">
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
              <CRow>
                    <!-- Request new link -->
                    <CCol col="6" class="text-left">
                        <CButton color="link" class="px-0" @click="requestNewLink">{{ ('Get new link') }}</CButton>
                    </CCol>
                    <!-- Confirm -->
                    <CCol col="6" class="text-right">
                        <CButton v-if="!loading" color="primary" class="px-4" @click="newPasswordClicked">{{ ('Accept changes') }}</CButton>
                        <CSpinner v-else variant="success" small></CSpinner>
                    </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import InputUpdatePassword from "@/classes/InputUpdatePassword";

export default {
  data () {
    return {
        email: '',
        token: '',
        password: '',
        confirmPassword: '',
        newPasswordError: '',
        passwordValidationMessage: '',
        submitted: false,
        loading: false
    }
  },
  computed:
  {
    ...mapState({ 
        alertType: state => state.alert.type,
        alertOutput: state => state.alert.output
    }),
    ...mapState('account', ['requestLink']),
    ...mapState('account', ['update']),
    isPasswordValid: function()
    {
        return this.passwordValidator() != false;
    },
    isLinkValid: function()
    {
        return this.email && this.token;
    }
  },
  name: 'Login',
  methods: {
    ...mapActions('account', ['updatePassword']),
    ...mapActions({
          clearAlert: 'alert/clear' 
      }),
    newPasswordClicked: function(){
      this.submitted = true;

      if (this.submitted) 
      {
        this.loading = true;

        var input = new InputUpdatePassword(this.password, this.email, this.token);
        this.updatePassword(input);
      }
    },
    
    requestNewLink()
    {
        this.$router.push({name: "Login", params: { email: this.email, isRecoveringPassword: true } });
    }
  },
  created: function()
  {
    this.clearAlert();
  },
  mounted: function()
  {
      this.email = this.$route.query.email;
      this.token = this.$route.query.token.replaceAll(' ', '+');
      
  },
  watch: 
  { 
    alertType: function()
    {
      this.newPasswordError = this.alertType === "alert-danger";
    },
    alertOutput: function()
    {
      this.newPasswordError = this.alertType === "alert-danger";
    },
    email: function()
    {
      this.newPasswordError = '';
    },
    password: function()
    {
      this.newPasswordError = '';
    },
    update: function(val) // Password update
    {
        if(val !== undefined)
        {
            var resultMessage;
            var goToLogin = false;

            if(val)
            {
                resultMessage = ("Password update success");
                goToLogin = true;
            }
            else
            {
                resultMessage = ("Password update error");
            }

            alert(resultMessage);
            this.loading = false;

            if(goToLogin)
            {
                this.$router.push('/login');
            }
        }
    },
  }
}
</script>
